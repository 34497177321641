<template>
  <b-modal id="provider-user-modal" title="Proveedor" hide-footer>
    <div class="provider-user" v-if="selectedUser">
      <div v-if="selectedUser.logo && selectedUser.name">
        <b-avatar
          size="64"
          :src="selectedUser.logo"
          :text="avatarText(selectedUser.name)"
          :variant="`light-primary`"
          :alt="selectedUser.name"
        />
      </div>
      <b-link
        class="font-weight-bold d-block text-nowrap"
        v-if="selectedUser.name"
      >
        {{ selectedUser.name }}
      </b-link>
      <div v-if="selectedUser.email">
        <small class="text-muted">
          {{ selectedUser.email }}
        </small>
      </div>
      <div v-if="selectedUser.phone_number">
        <small class="text-muted">
          {{ selectedUser.phone_number.slice(2) }}
        </small>
      </div>
    </div>

    <ul class="mt-2" v-if="selectedUser && selectedUser.scoped_roles">
      <small>Tiendas asignadas al proveedor:</small>
      <div v-if="attachedStores.length > 0">
        <li
          class="mt-2"
          v-for="store_product in attachedStores"
          :key="store_product.role_resource_id"
        >
          <div class="align-items-center d-flex justify-between">
            <div class="align-items-center d-flex">
              <b-avatar
                size="32"
                :src="store_product.role_resource_logo"
                :text="avatarText(store_product.role_resource_name)"
                :variant="`light-primary`"
              />

              <div class="ml-50">
                <b-link class="font-weight-bold d-block">
                  {{ store_product.role_resource_name }}
                </b-link>
              </div>
            </div>
            <b-button
              variant="ghost"
              class="ml-auto"
              @click="
                handleDelete({
                  supplier_id: selectedUser.id,
                  remove_store_ids: [store_product.role_resource_id],
                })
              "
            >
              <feather-icon icon="TrashIcon" class="text-danger" size="16" />
            </b-button>
          </div>
        </li>
      </div>
      <li v-else>
        <small>No hay tiendas asignadas</small>
      </li>
    </ul>
  </b-modal>
</template>

<script>
import { avatarText } from "@core/utils/filter"

export default {
  components: {
    avatarText,
  },
  setup() {
    return {
      // Filter
      avatarText,
    }
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => {},
    },
    handleDelete: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    attachedStores() {
      if (!this.selectedUser) return []

      return this.selectedUser.scoped_roles.filter(
        (role) => role.role_resource_type === "Store"
      )
    },
  },
}
</script>

<style lang="scss">
.provider-user {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  ul {
    width: 100%;
    max-height: 200px;
  }

  li {
    margin: 5px 0;
  }
}
</style>
