<template>
  <ul class="providers-list">
    <li
      v-for="user in users"
      :key="user.id"
      class="mb-1"
      @click="openModal(user)"
    >
      <div class="d-flex align-items-center">
        <b-avatar
          size="32"
          :src="user.logo"
          :text="avatarText(user.name)"
          :variant="`light-primary`"
          :alt="user.name"
        />
        <div class="ml-50">
          <b-link class="font-weight-bold d-block text-break">
            {{ user.name }}
          </b-link>
          <div>
            <small class="text-muted text-wrap text-break">{{
              user.email
            }}</small>
          </div>

          <div>
            <small class="text-muted text-wrap text-break">
              {{ user.phone_number.slice(2) }}
            </small>
          </div>
        </div>

        <b-dropdown variant="link" no-caret class="ml-auto" boundary="viewport">
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            :to="{
              name: 'establishment-buy-inventory',
              params: {
                id: $route.params.id,
              },
              query: { provider: user.id },
            }"
          >
            <feather-icon icon="ShoppingCartIcon" />
            <span class="align-middle ml-50">Comprar</span>
          </b-dropdown-item>

          <b-dropdown-item
            :to="{
              name: 'establishment-providers-attach-stores',
              params: { id: $route.params.id, supplier_id: user.id },
            }"
          >
            <feather-icon icon="LinkIcon" />
            <span class="align-middle ml-50">Anexar tienda</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click.native.stop="
              handleDelete({
                supplier_id: user.id,
                remove_establishment_id: $route.params.id,
              })
            "
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Eliminar</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </li>
  </ul>
</template>

<script>
import { avatarText } from "@core/utils/filter"

export default {
  components: {
    avatarText,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    openModal: {
      type: Function,
      default: () => {},
    },
    handleDelete: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.providers-list {
  display: block;
  padding: 0 20px;

  li {
    cursor: pointer;
  }

  @media (min-width: 767.98px) {
    display: none;
  }
}
</style>
