<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <b-row class="d-flex justify-content-between align-items-center mx-auto">
        <h2 class="grow">Proveedores asociados</h2>

        <div class="d-flex align-items-center justify-content-end">
          <b-button
            class="add-button-rounded"
            variant="success"
            :to="{
              name: 'auth-register-establishment-supplier',
              params: { id: $route.params.id },
            }"
          >
            <feather-icon icon="PlusIcon" size="18" />
          </b-button>
        </div>
      </b-row>

      <!-- Description -->
      <b-row class="mt-1 mb-1 providers-table">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <small>
            Aquí podrás ver y agregar a todos tus proveedores. Además, podrás
            ver qué productos y a qué tienda surten
          </small>
        </b-col>
      </b-row>

      <b-row class="mt-1 mb-1 description">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <small> Toca el nombre del proveedor para ver detalles </small>
        </b-col>
      </b-row>

      <!-- Searchbar -->
      <b-row clas="mt-3">
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="userName"
              placeholder="Buscar nombre"
              class="search-user"
            />
            <b-input-group-append v-if="userName">
              <b-button
                variant="outline-warning"
                @click="clearSearchbarAndResetSearch()"
              >
                Borrar
              </b-button>
            </b-input-group-append>
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <Overlay :show="loading">
      <providers-list
        :users="users"
        :openModal="openModal"
        :handleDelete="handleDelete"
      />

      <establishment-suppliers-table
        :users="users"
        :handleDelete="handleDelete"
      />
    </Overlay>

    <user-provider-modal
      :selectedUser="selectedUser"
      :handleDelete="handleDelete"
    />

    <div class="mx-2 mb-2">
      <pagination
        :loading="loading"
        :handlePagination="handlePagination"
        :pagination="pagination"
        :entriesPerPage.sync="entriesPerPage"
      />
    </div>
  </b-card>
</template>

<script>
import _ from "underscore"
import vSelect from "vue-select"
import { mapActions, mapMutations } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import ProvidersList from "./ProvidersList.vue"
import UserProviderModal from "./UserProviderModal.vue"
import Pagination from "@/@core/components/Pagination.vue"
import EstablishmentSuppliersTable from "./EstablishmentSuppliersTable2.vue"

import messagesMixin from "@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"
import Overlay from "@/@core/components/Overlay.vue"

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Overlay,

    ProvidersList,
    UserProviderModal,
    EstablishmentSuppliersTable,
    Pagination,
  },
  mixins: [messagesMixin],
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  data() {
    return {
      userName: null,
      loading: false,
      entriesPerPage: "10",
      selectedUser: null,
      users: [],
      pagination: {},
    }
  },
  watch: {
    userName: _.debounce(function () {
      this.getUsers({
        by_name: this.userName,
        per_page: this.entriesPerPage,
      })
    }, 500),

    entriesPerPage() {
      this.getUsers({
        by_name: this.userName,
        per_page: this.entriesPerPage,
      })
    },
  },

  beforeMount() {
    this.getUsers()
  },

  methods: {
    ...mapMutations("users", ["filterScopedStoreProducts"]),
    ...mapActions("users", [
      "updateEstablishmentSupplier",
      "updateSupplierStoreProducts",
      "fetchSuppliers",
    ]),
    clearSearchbarAndResetSearch() {
      this.userName = ""
    },

    getUsers({ page, per_page, ...filters } = {}) {
      this.loading = true

      return this.fetchSuppliers({
        ...filters,
        by_resource_id: this.$route.params.id,
        by_active_status: true,
        by_role: "establishment_supplier",
        meta: {
          pagination: {
            page: page || 1,
            per_page: per_page,
          },
        },
      })
        .then((res) => {
          this.users = res.data
          this.pagination = res.meta.pagination
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response?.data)
          this.errorToast("Error", errors[0])
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleRemoveSupplierProduct(role, supplier) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "No podrás revertir esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (!result.value) return

        this.updateSupplierStoreProducts({
          params: {
            action: "remove",
            supplier_id: supplier.id,
            remove_products_attributes: [
              {
                id: role.role_resource_id,
              },
            ],
          },
        })
          .then(() => {
            this.successToast("Producto eliminado")

            this.getUsers().then(() => {
              this.selectedUser = this.users.find(
                (user) => user.id === supplier.id
              )
            })
          })
          .catch((error) => {
            const errors = handleAPIErrors(error.response?.data)
            this.errorToast("Error", errors[0])
          })
      })
    },

    handleDelete({
      supplier_id,
      remove_store_ids,
      remove_establishment_id,
    } = {}) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "No podrás revertir esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.updateEstablishmentSupplier({
            supplier_id,
            remove_store_ids,
            remove_establishment_id,
          })
            .then((res) => {
              this.successToast("Proveedor eliminado")
              this.$bvModal.hide("provider-user-modal")
              this.getUsers()
            })
            .catch((error) => {
              const errors = handleAPIErrors(error.response?.data)
              this.errorToast("Error", errors[0])
            })
        }
      })
    },

    openModal(user) {
      this.selectedUser = user
      this.$bvModal.show("provider-user-modal")
    },

    handlePagination({ page, per_page }) {
      this.getUsers({
        page,
        per_page,
        by_name: this.userName,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.add-button-rounded {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input {
  max-width: 200px;
}

.providers-table {
  display: none;

  @media (min-width: 767.98px) {
    display: block;
  }
}

.description {
  display: block;

  @media (min-width: 767.98px) {
    display: none;
  }
}
</style>
