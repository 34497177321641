<template>
  <b-table
    ref="refSupplierListTable"
    :items="users"
    responsive
    :fields="tableColumns"
    primary-key="id"
    show-empty
    empty-text="No se encontraron provedores"
    class="position-relative providers-table"
  >
    <!-- Column: Establishment Supplier -->
    <template #cell(name)="data">
      <b-media vertical-align="center">
        <template #aside>
          <b-avatar
            size="32"
            :src="data.item.logo"
            :text="avatarText(data.item.name)"
            :variant="`light-primary`"
          />
        </template>
        <b-link class="font-weight-bold d-block text-nowrap">
          {{ data.item.name }}
        </b-link>
        <small class="text-muted">{{ data.item.email }}</small>
      </b-media>
    </template>

    <!-- Column: Contact -->
    <template #cell(cel_number)="data">
      <template>
        <div>
          <b-badge pill :variant="'light-success'">
            {{ data.item.phone_number.slice(2) }}
          </b-badge>
        </div>
      </template>
    </template>

    <!-- Details button -->
    <template #cell(stores)="row">
      <b-button
        size="sm"
        :variant="'primary'"
        @click="row.toggleDetails"
        class="mr-2"
      >
        {{ row.detailsShowing ? "Ocultar" : "Ver" }} tiendas
      </b-button>
    </template>

    <!-- Actions -->
    <template #cell(actions)="data">
      <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item
          :to="{
            name: 'establishment-buy-inventory',
            params: {
              id: $route.params.id,
            },
            query: { provider: data.item.id },
          }"
        >
          <feather-icon icon="ShoppingCartIcon" />
          <span class="align-middle ml-50">Comprar</span>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{
            name: 'establishment-providers-attach-stores',
            params: { id: $route.params.id, supplier_id: data.item.id },
          }"
        >
          <feather-icon icon="LinkIcon" />
          <span class="align-middle ml-50">Anexar tienda</span>
        </b-dropdown-item>

        <b-dropdown-item
          @click="
            handleDelete({
              supplier_id: data.item.id,
              remove_establishment_id: $route.params.id,
            })
          "
        >
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Eliminar</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <template #row-details="data">
      <b-card>
        <b-row class="mb-2">
          <b-table
            :items="
              data.item.scoped_roles.filter(
                (role) => role.role_resource_type === 'Store'
              )
            "
            responsive
            :fields="tableColumnsForStoreProducts"
            primary-key="id"
            show-empty
            empty-text="No se encontraron tiendas"
            class="text-left"
          >
            <!-- Product  -->
            <template #cell(store_name)="store_product">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="store_product.item.role_resource_logo"
                    :text="avatarText(store_product.item.role_resource_name)"
                    :variant="`light-primary`"
                  />
                </template>
                <b-link class="font-weight-bold d-block text-nowrap">
                  {{ store_product.item.role_resource_name }}
                </b-link>
              </b-media>
            </template>

            <template #cell(actions)="store_product">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  @click="
                    handleDelete({
                      supplier_id: data.item.id,
                      remove_store_ids: [store_product.item.role_resource_id],
                    })
                  "
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Borrar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-row>
      </b-card>
    </template>
  </b-table>
</template>

<script>
import { avatarText } from "@core/utils/filter"

export default {
  data() {
    return {
      tableColumns: [
        {
          key: "name",
          label: "Proveedor",
        },
        {
          key: "cel_number",
          label: "Contacto",
        },
        {
          key: "stores",
          label: "Tiendas asignadas",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],

      tableColumnsForStoreProducts: [
        {
          key: "store_name",
          label: "Tienda",
        },
        {
          key: "actions",
          label: "Acciones",
        },
      ],
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    handleDelete: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
