var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"refSupplierListTable",staticClass:"position-relative providers-table",attrs:{"items":_vm.users,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":"No se encontraron provedores"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.logo,"text":_vm.avatarText(data.item.name),"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.email))])],1)]}},{key:"cell(cel_number)",fn:function(data){return [[_c('div',[_c('b-badge',{attrs:{"pill":"","variant":'light-success'}},[_vm._v(" "+_vm._s(data.item.phone_number.slice(2))+" ")])],1)]]}},{key:"cell(stores)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":'primary'},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.detailsShowing ? "Ocultar" : "Ver")+" tiendas ")])]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
          name: 'establishment-buy-inventory',
          params: {
            id: _vm.$route.params.id,
          },
          query: { provider: data.item.id },
        }}},[_c('feather-icon',{attrs:{"icon":"ShoppingCartIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Comprar")])],1),_c('b-dropdown-item',{attrs:{"to":{
          name: 'establishment-providers-attach-stores',
          params: { id: _vm.$route.params.id, supplier_id: data.item.id },
        }}},[_c('feather-icon',{attrs:{"icon":"LinkIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Anexar tienda")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleDelete({
            supplier_id: data.item.id,
            remove_establishment_id: _vm.$route.params.id,
          })}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Eliminar")])],1)],1)]}},{key:"row-details",fn:function(data){return [_c('b-card',[_c('b-row',{staticClass:"mb-2"},[_c('b-table',{staticClass:"text-left",attrs:{"items":data.item.scoped_roles.filter(
              function (role) { return role.role_resource_type === 'Store'; }
            ),"responsive":"","fields":_vm.tableColumnsForStoreProducts,"primary-key":"id","show-empty":"","empty-text":"No se encontraron tiendas"},scopedSlots:_vm._u([{key:"cell(store_name)",fn:function(store_product){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":store_product.item.role_resource_logo,"text":_vm.avatarText(store_product.item.role_resource_name),"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(store_product.item.role_resource_name)+" ")])],1)]}},{key:"cell(actions)",fn:function(store_product){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleDelete({
                    supplier_id: data.item.id,
                    remove_store_ids: [store_product.item.role_resource_id],
                  })}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Borrar")])],1)],1)]}}],null,true)})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }