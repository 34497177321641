var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"providers-list"},_vm._l((_vm.users),function(user){return _c('li',{key:user.id,staticClass:"mb-1",on:{"click":function($event){return _vm.openModal(user)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"size":"32","src":user.logo,"text":_vm.avatarText(user.name),"variant":"light-primary","alt":user.name}}),_c('div',{staticClass:"ml-50"},[_c('b-link',{staticClass:"font-weight-bold d-block text-break"},[_vm._v(" "+_vm._s(user.name)+" ")]),_c('div',[_c('small',{staticClass:"text-muted text-wrap text-break"},[_vm._v(_vm._s(user.email))])]),_c('div',[_c('small',{staticClass:"text-muted text-wrap text-break"},[_vm._v(" "+_vm._s(user.phone_number.slice(2))+" ")])])],1),_c('b-dropdown',{staticClass:"ml-auto",attrs:{"variant":"link","no-caret":"","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
            name: 'establishment-buy-inventory',
            params: {
              id: _vm.$route.params.id,
            },
            query: { provider: user.id },
          }}},[_c('feather-icon',{attrs:{"icon":"ShoppingCartIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Comprar")])],1),_c('b-dropdown-item',{attrs:{"to":{
            name: 'establishment-providers-attach-stores',
            params: { id: _vm.$route.params.id, supplier_id: user.id },
          }}},[_c('feather-icon',{attrs:{"icon":"LinkIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Anexar tienda")])],1),_c('b-dropdown-item',{nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleDelete({
              supplier_id: user.id,
              remove_establishment_id: _vm.$route.params.id,
            })}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Eliminar")])],1)],1)],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }